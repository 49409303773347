.App {
    display: flex;
    flex-direction: column;

    align-items: stretch;
    height: 100vh;
    overflow: hidden;
}

.icon {
    transition: all 0.2s;
    cursor: pointer;
}

.icon:hover {
    filter: brightness(0.9);
}

.icon:active {
    filter: brightness(0.8);
}
